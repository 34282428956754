import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Sidepanel from '../../layouts/Sidepanel'
import Fillup from '../../assets/icons/fillup.svg'
import CircleExclamation from '../../assets/icons/circle-exclamation.svg'
import ChevronRight from '../../assets/icons/chevron-right.svg'
import Spinner from '../../components/Spinner'
import Api from '../../services/Api'
import moment from 'moment'
import { getValue } from 'firebase/remote-config'
import { useAppContext } from '../../context'
import { currencyFormatter, getOrderMethodLabel } from '../../utils'

const TransactionDetails = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [transactionData, setTransactionData] = useState({ id })
  const { remoteConfig } = useAppContext()

  const fees = useMemo(() => {
    if (!!remoteConfig) {
      const feesObject = getValue(remoteConfig, 'fees')
      if (!feesObject) {
        return {}
      }

      return JSON.parse(feesObject.asString())
    } else {
      return {}
    }
  }, [remoteConfig])

  const paymentData = useMemo(() => {
    if (transactionData?.payment_status !== 'paid' || !fees) {
      return null
    } else {
      const fee = fees[transactionData.payment_method] || {
        percent: 0,
        offset: 0,
      }
      const platformFee = fees.platform_fee
      const commissionFee = 0

      const total =
        transactionData.gross_amount -
        (transactionData.gross_amount * fee.percent * 0.01 + fee.offset) -
        (transactionData.gross_amount * platformFee.percent * 0.01 +
          platformFee.offset) -
        commissionFee

      return {
        fee,
        platformFee,
        commissionFee,
        total,
      }
    }
  }, [fees, transactionData])

  const init = useCallback(async () => {
    try {
      const response = await Api.getTransactionDetails({ id })
      if (!response.success) throw new Error(response.message)

      setTransactionData(response.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    init()
  }, [init])

  const paymentMethodLabels = {
    gcash: 'GCash',
    grab_pay: 'GrabPay',
    card: 'Card',
    cash: 'Cash',
  }

  const paymentStatus = useMemo(() => {
    if (paymentData && transactionData.payment_status) {
      return transactionData.payment_status
    }

    if (
      transactionData.payment_method === 'cash' &&
      transactionData.order_status
    ) {
      return transactionData.order_status
    }
  }, [
    paymentData,
    transactionData.payment_status,
    transactionData.order_status,
    transactionData.payment_method,
  ])

  return (
    <div className="transaction-details">
      <Sidepanel />
      <div className="content-wrapper">
        <div className="content-header">
          <p className="content-header-title">
            <Link to="/admin/transactions">All Transactions</Link>
            <img
              style={{ margin: '0 10px' }}
              className="section-header-icon"
              src={ChevronRight}
              alt=""
            />

            <span>{id}</span>
          </p>
          <p className="content-header-sub">Transaction details</p>
        </div>
        <div className="content">
          {isLoading ? (
            <div className="spinner">
              <Spinner type="Oval" color="#ffd400" height={40} width={40} />
            </div>
          ) : (
            <>
              <div className="section order-details-section">
                <div className="row align-center">
                  <img className="section-header-icon" src={Fillup} alt="" />
                  <span className="section-header-title">Order Details</span>
                </div>
                <div className="section-content">
                  <div className="section-info-wrapper">
                    <span className="section-info-label">Order ID</span>
                    <span className="section-info-value">{id}</span>
                  </div>
                  <div className="section-info-wrapper">
                    <span className="section-info-label">Date and Time</span>
                    <span className="section-info-value">
                      {moment(transactionData.date).format(
                        'MMM DD, YYYY [at] hh:mma'
                      )}
                    </span>
                  </div>
                  <div className="section-info-wrapper">
                    <span className="section-info-label">Post Title</span>
                    <span className="section-info-value">
                      {transactionData.post_title}
                    </span>
                  </div>
                  <div className="section-info-wrapper">
                    <span className="section-info-label">
                      Seller / Service Provider
                    </span>
                    <span className="section-info-value">
                      {transactionData.seller_name}
                    </span>
                  </div>
                  <div className="section-info-wrapper">
                    <span className="section-info-label">Order Method</span>
                    <span className="section-info-value">
                      {transactionData?.delivery?.type === 'courier'
                        ? 'In-App Delivery'
                        : transactionData?.delivery?.type === 'own'
                        ? 'In-House Delivery'
                        : getOrderMethodLabel(transactionData.order_method)}
                      {(transactionData?.delivery?.type === 'courier' &&
                        transactionData?.delivery?.courier === 'alleasygo' &&
                        transactionData?.delivery_status !== 'delivered' &&
                        transactionData?.order_status === 'completed') ||
                      transactionData?.delivery_status === 'canceled'
                        ? ', Plan Bee'
                        : ''}
                    </span>
                  </div>
                  <div className="section-info-wrapper">
                    <span className="section-info-label">
                      {transactionData.post_type === 'need'
                        ? 'Finder'
                        : 'Customer'}
                    </span>
                    <span className="section-info-value">
                      {transactionData.post_type === 'need'
                        ? transactionData.seller_name
                        : transactionData.buyer_name}
                    </span>
                  </div>
                </div>
              </div>
              {((transactionData?.payment_status === 'paid' && paymentData) ||
                (transactionData.cash_payment &&
                  transactionData.payment_method === 'cash' &&
                  transactionData.order_status === 'completed')) && (
                <>
                  <div className="section payment-details-section">
                    <span className="section-header-title">
                      Payment Details
                    </span>
                    <div className="section-content">
                      {!!transactionData.payment_id && (
                        <div className="section-info-wrapper">
                          <span className="section-info-label">Payment ID</span>
                          <span className="section-info-value">
                            {transactionData.payment_id}
                          </span>
                        </div>
                      )}
                      <div className="section-info-wrapper">
                        <span className="section-info-label">
                          Payment Method
                        </span>
                        <span className="section-info-value">
                          {paymentMethodLabels[transactionData.payment_method]}
                        </span>
                      </div>
                      {!!paymentData && (
                        <div className="section-info-wrapper">
                          <span className="section-info-label">Paid at</span>
                          <span className="section-info-value">
                            {!!transactionData.date_paid &&
                              moment(transactionData.date_paid).format(
                                'MMM DD, YYYY [at] hh:mma'
                              )}
                          </span>
                        </div>
                      )}
                      <div className="section-info-wrapper">
                        <span className="section-info-label">
                          {transactionData.post_type === 'need'
                            ? 'Respondent'
                            : 'Seller / Service Provider'}
                        </span>
                        <span className="section-info-value">
                          {transactionData.post_type === 'need'
                            ? transactionData.buyer_name
                            : transactionData.seller_name}
                        </span>
                      </div>
                      <div className="section-info-wrapper">
                        <span className="section-info-label">Status</span>
                        <span
                          className={
                            'section-info-value payment-status-value ' +
                            paymentStatus
                          }>
                          {paymentStatus}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="section amount-section">
                    <span className="section-header-title">Amount</span>
                    {paymentData && (
                      <>
                        <div className="section-content">
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Gross Amount
                            </span>
                            <span className="section-info-value">
                              {currencyFormatter.format(
                                transactionData.gross_amount || 0
                              )}
                            </span>
                          </div>
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Delivery Fee Reimbursement
                            </span>
                            <span className="section-info-value">
                              {currencyFormatter.format(
                                transactionData?.delivery_status ===
                                  'delivered' &&
                                  transactionData?.delivery?.type === 'courier'
                                  ? 0
                                  : transactionData.delivery_fee
                              )}
                            </span>
                          </div>
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Paymongo Fee
                            </span>
                            <span className="section-info-value">
                              -{' '}
                              {currencyFormatter.format(
                                paymentData.fee.percent *
                                  transactionData.gross_amount *
                                  0.01 +
                                  paymentData.fee.offset
                              )}{' '}
                              {`(${paymentData.fee.percent}%${
                                paymentData.fee.offset
                                  ? ` + ${currencyFormatter.format(
                                      paymentData.fee.offset
                                    )}`
                                  : ''
                              })`}
                            </span>
                          </div>
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Platform Fee
                            </span>
                            <span className="section-info-value">
                              -{' '}
                              {currencyFormatter.format(
                                paymentData.platformFee.percent *
                                  transactionData.gross_amount *
                                  0.01 +
                                  paymentData.platformFee.offset
                              )}{' '}
                              {!!paymentData.platformFee.percent &&
                                `(${paymentData.platformFee.percent}%)`}
                            </span>
                          </div>
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Commission Fee
                            </span>
                            <span className="section-info-value">
                              -{' '}
                              {currencyFormatter.format(
                                paymentData.commissionFee
                              )}{' '}
                              {!!paymentData.commissionFee &&
                                `(${paymentData.commissionFee}%)`}
                            </span>
                          </div>
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Net Amount
                            </span>
                            <span className="section-info-value net-amount">
                              {currencyFormatter.format(paymentData.total)}
                            </span>
                          </div>
                        </div>
                        <div className="payment-note">
                          <img
                            className="section-header-icon"
                            src={CircleExclamation}
                            alt=""
                          />
                          <span>
                            Payment is expected to be eligible for payout by{' '}
                            <strong>Payout Date</strong>
                          </span>
                        </div>
                      </>
                    )}

                    {transactionData.cash_payment && (
                      <>
                        <div className="section-content">
                          <div className="section-info-wrapper">
                            <span className="section-info-label">Total</span>
                            <span className="section-info-value">
                              {currencyFormatter.format(
                                transactionData.gross_amount || 0
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="section-content">
                          <div className="section-info-wrapper">
                            <span className="section-info-label">
                              Cash payment
                            </span>
                            <span className="section-info-value">
                              {currencyFormatter.format(
                                transactionData.cash_payment || 0
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="section-content">
                          <div className="section-info-wrapper">
                            <span className="section-info-label">Change</span>
                            <span className="section-info-value">
                              {currencyFormatter.format(
                                (transactionData.cash_payment || 0) -
                                  (transactionData.gross_amount || 0)
                              )}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default TransactionDetails
