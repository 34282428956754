import { getAuth } from 'firebase/auth'
import { getRemoteConfig } from 'firebase/remote-config'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { firebaseApp } from '../App'

export const Context = createContext()
export const ContextProvider = ({ children }) => {
  const auth = getAuth()
  const [pendingRequestsLoader, setPendingRequestsLoader] = useState(true)
  const [activePendingRequestLoader, setActivePendingRequestLoader] = useState(
    true
  )
  const [user, setUser] = useState(null)
  const [pendingRequests, setPendingRequest] = useState([])
  const [activePendingRequest, setActivePendingRequest] = useState()
  const [types, setTypes] = useState()
  const [sort, setSort] = useState()
  const [remoteConfig, setRemoteConfig] = useState()
  const [token, setToken] = useState(sessionStorage.getItem('token'))
  const [role, setRole] = useState(sessionStorage.getItem('role'))

  const init = async () => {
    const app = await firebaseApp
    setRemoteConfig(getRemoteConfig(app))
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    return auth.onAuthStateChanged(async user => {
      setUser(user)
      if (user) {
        const { claims } = await user.getIdTokenResult()
        const token = await user.getIdToken(true)

        if (claims.admin) {
          sessionStorage.setItem('role', 'admin')
          setRole('admin')
        }
        if (token) {
          sessionStorage.setItem('token', token)
          sessionStorage.setItem('token-timestamp', Date.now().toString())
          setToken(token)
        }
      } else {
        setToken(null)
        sessionStorage.removeItem('role')
        sessionStorage.removeItem('token')
      }
    })
  }, [auth])

  return (
    <Context.Provider
      value={{
        user,
        setUser,
        pendingRequests,
        setPendingRequest,
        activePendingRequest,
        setActivePendingRequest,
        types,
        setTypes,
        sort,
        setSort,
        pendingRequestsLoader,
        setPendingRequestsLoader,
        activePendingRequestLoader,
        setActivePendingRequestLoader,
        remoteConfig,
        token,
        role,
      }}>
      {children}
    </Context.Provider>
  )
}

export const useAppContext = () => {
  const _context = useContext(Context)

  if (!_context) {
    throw new Error('You have forgotten to use AppContext.')
  }

  return _context
}
